<template>
  <!--  保险公司-->
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>费用列表</el-breadcrumb-item>
      <el-breadcrumb-item>加油记录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <div class="box">
        <div class="top">
          <span>使用单位：</span>
          <el-select v-model="current_org" clearable @clear="qingchu">
            <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug"></el-option>
          </el-select>
        </div>
        <div class="top">
          <span>车辆：</span>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="key"
            :fetch-suggestions="salesperson"
            value-key="auto_number"
            @select="XiaohandleSelect($event, '车牌号')"
          ></el-autocomplete>
        </div>
        <el-button type="primary" @click="opentan()">添加信息</el-button>
      </div>
      <div class="bot">
        <div class="top">
          <span>日期：</span>
          <el-date-picker
            @change="gettime"
            v-model="timeFrom"
            :picker-options="pickerOptions"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <el-button type="primary" plain @click="frontDownload" class="btn">导出Excle</el-button>
      </div>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column label="车牌号">
          <template slot-scope="scope">
            <div id="test" @click="hader(scope.row)">{{ scope.row.auto_number }}</div>
          </template>
        </el-table-column>
        <el-table-column label="汽油单价(元)">
          <template slot-scope="scope">{{ scope.row.unit_price }}元</template>
        </el-table-column>
        <el-table-column label="加油总额(元)">
          <template slot-scope="scope">{{ toFixed(scope.row.total_price) }}元</template>
        </el-table-column>
        <el-table-column label="加油量(L)">
          <template slot-scope="scope">{{ scope.row.refueling_volume }}L</template>
        </el-table-column>
        <el-table-column prop="refueling_time" label="加油时间"></el-table-column>
        <el-table-column label="公里数(Km)">
          <template slot-scope="scope">{{ toFixed(scope.row.kilometre) }}</template>
        </el-table-column>
        <el-table-column label="里程数">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.kilometre_files" :previewSrcList="[scope.row.kilometre_files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="小票">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.refueling_files" :previewSrcList="[scope.row.refueling_files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-view" @click="hader(scope.row)"></el-button>

            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog title="加油记录" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
        <el-form-item label="车辆" prop="shiyong">
          <!-- <el-select v-model="dform.auto_id" placeholder="车辆">
            <el-option v-for="item in carList" :key="item.id" :label="item.auto_number" :value="item.id"></el-option>
          </el-select> -->
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="auto_id_name"
            :fetch-suggestions="salespersons"
            value-key="auto_number"
            @select="XiaohandleSelects($event, '车牌号')"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="汽油单价">
          <div class="el-select">
            <el-input v-model="dform.unit_price" placeholder="汽油单价">
              >
              <i slot="prefix" class="iconfont icon-jiage-copy"></i>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="加油总金额">
          <div class="el-select">
            <el-input v-model="dform.total_price" placeholder="加油总金额">
              >
              <i slot="prefix" class="iconfont icon-jiage-copy"></i>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="当前公里数">
          <div class="el-select"><el-input v-model="dform.kilometre" placeholder="当前公里数">></el-input></div>
        </el-form-item>
        <el-form-item label="加油量(L)">
          <div class="el-select"><el-input v-model="dform.refueling_volume" placeholder="加油量">></el-input></div>
        </el-form-item>
        <el-form-item label="加油时间">
          <el-date-picker v-model="dform.refueling_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="加油时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="加油小票" prop="icon">
          <el-upload
            accept=".pdf, .jpg, .jpeg, .png"
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, this.dform.refueling_filess)
              }
            "
            :http-request="httpRequest"
            :multiple="false"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="dform.refueling_filess"
          >
            <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('加油小票')">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="dform.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <el-button v-if="tantype !== 'look'" :disabled="addimgtype" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 点击车牌号的弹窗 -->
    <el-dialog :title="nodetitle" :visible.sync="dialogTableVisible" width="1100px" :before-close="handleDialogClose">
      <div class="riqi">
        <el-date-picker
          @change="gettimes"
          v-model="timeFroms"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <div class="nei">
        <div class="texts">
          <div class="kuan">本月油耗成本</div>
          <div class="kuan">{{ toFixed(total_prices) }}元</div>
        </div>
        <div class="texts">
          <div class="kuan">本月平均油耗</div>
          <div class="kuan">{{ oil_consumptions }}L/100KM</div>
        </div>
      </div>
      <el-table :data="gridData" class="log" style="text-align: center">
        <el-table-column align="center" property="refueling_time" label="加油提报时间" width="150"></el-table-column>
        <el-table-column align="center" property="unit_price" label="油料单价(元)" width="100"></el-table-column>
        <el-table-column align="center" property="refueling_volume" label="加油量/L" width="100"></el-table-column>
        <el-table-column align="center" property="total_price" label="加油总额(元)" width="100">
          <template slot-scope="scope">{{ toFixed(scope.row.total_price) }}</template>
        </el-table-column>
        <el-table-column align="center" property="kilometre" label="里程数(KM)" width="100">
          <template slot-scope="scope">{{ toFixed(scope.row.kilometre) }}</template>
        </el-table-column>
        <el-table-column align="center" property="oil_consumption" label="本次油耗(L/100KM)" width="130"></el-table-column>
        <el-table-column align="center" label="里程照片">
          <template slot-scope="scope">
            <el-image :z-index="5000" style="width: 100px; height: 80px" :src="scope.row.kilometre_files" :previewSrcList="[scope.row.kilometre_files]"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="小票照片">
          <template slot-scope="scope">
            <el-image :z-index="5000" style="width: 100px; height: 80px" :src="scope.row.refueling_files" :previewSrcList="[scope.row.refueling_files]"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageDatas" @newsize="bianzhis" @newpape="bianyes" />
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { AddRefueling, DelRefueling, EditRefueling, GetRefuelingList, GetautoList, GetCompanyList, GetInsurancetypeList, Getxiangmu } from '../../../api/car'
import { Upimg, Daochu } from '@/api/upimg'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      nodetitle: '',
      yue: '',
      current_org: '', // 下拉框选中的值
      trashtypeData: [], // 下拉框的数据
      timeFrom: [], // 时间选择器的数据
      timeFroms: [], // 时间选择器的数据
      key: '', // 搜索车辆
      params: '', // 导出的连接数据
      total_prices: '', // 本月油耗成本
      oil_consumptions: '', // 本月平均油耗
      gridData: [], // 点击车牌号的数据
      dialogTableVisible: false, // 用油分析弹窗
      addimgtype: false, // 上传控制
      dialogVisible: false, // 弹窗控制
      tableData: [], // 列表数据
      carList: [], // 车辆列表
      auto_id: '', // 车辆id
      userlist: [], // 搜索车辆的数据
      id: '', // 搜索车辆的id
      start_time_at: '', // 起始日期
      end_time_at: '', // 结束日期
      start_time_ats: '', // 起始日期
      end_time_ats: '', // 结束日期
      refueling_filess: [],
      auto_id_name: '',
      dform: {},
      tantype: 'add', // 弹窗按钮的状态
      // 验证规则
      drules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 点击车牌号 弹窗的分页值
      pageDatas: {
        page: 1,
        size: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.getrefuelinglistfun() // 拉取列表
    this.Getxiangmulist() // 拉取项目目录
  },
  filters: {
    filterMoney(num) {
      return (num / 100).toFixed(2)
    }
  },
  methods: {
    toFixed(val) {
      return parseFloat(val).toLocaleString('en', {
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2
      })
    },

    qingchu() {
      this.current_org = ''
      this.getrefuelinglistfun()
    },

    // 项目下拉框选中的触发事件
    choicedrug() {
      this.getrefuelinglistfun()
    },
    // 时间
    gettime(timeFrom) {
      this.start_time_at = timeFrom[0]
      this.end_time_at = timeFrom[1]
      this.getrefuelinglistfun()
    },
    // 时间
    gettimes(timeFroms) {
      this.start_time_ats = timeFroms[0]
      this.end_time_ats = timeFroms[1]
      this.sosuofun()
    },
    // 搜索车辆选中
    XiaohandleSelect(e, term) {
      this.id = e.id
      this.getrefuelinglistfun()
    },
    XiaohandleSelects(e, term) {
      console.log(e)
      this.dform.auto_id = e.id
      console.log(this.dform.auto_id)
      this.auto_number = e.auto_number
    },
    // 输入车辆匹配数据
    salesperson(queryString, cb) {
      this.getallfun(queryString, cb)
      this.getrefuelinglistfun()
    },
    salespersons(queryString, cb) {
      this.getallfun(queryString, cb)
    },
    // 导出Excle
    frontDownload() {
      var a = document.createElement('a') // 创建一个<a></a>标签
      a.href = `${this.params}`
      a.download = '下载.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    // 关闭用油详情时候让他的分页值等于1
    handleDialogClose() {
      this.dialogTableVisible = false
      this.pageDatas.page = 1
    },
    // 点击车牌号
    hader(scope) {
      // console.log(scope);
      this.nodetitle = '车辆油耗详情' + '>' + scope.auto_number
      this.auto_id = scope.auto_id
      this.dialogTableVisible = true
      this.sosuofun(this.auto_id)
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getrefuelinglistfun()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getrefuelinglistfun()
    },
    bianzhis(newSize) {
      // 分页值变化
      this.pageDatas.size = newSize
      this.sosuofun()
    },
    bianyes(newPape) {
      // 分页页数变化
      this.pageDatas.page = newPape
      this.sosuofun()
    },
    editfun(ev) {
      // 打开编辑look
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))

      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) {
      // 打开查看
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },

    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delrefuelingfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      // this.getallfun()
      this.dform.from_source = 'pc'
    },
    // eslint-disable-next-line no-unused-vars
    // 删除图片
    handleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {},
    // eslint-disable-next-line no-unused-vars
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data[0].url)
      switch (this.steupdata) {
        case '加油小票':
          this.dform.refueling_files = res.data[0].url
          console.log(this.dform.refueling_files)
          break
      }
      this.addimgtype = false
      // // 图片上传成功后把图片push到scroll中用于修改
      // console.log(res)
    },
    // 确定添加
    addgoto(type) {
      console.log(this.dform)
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addrefuelingfun(this.dform)
            }
            this.auto_id_name = ''
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editrefuelingfun(this.dform)
            }
          })
          // console.log(type)
          break
      }
    },

    // 取消
    cancelfun() {
      this.handleClose()
      this.auto_id_name = ''
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        refueling_filess: [] // 保单附件
      }
      this.tantype = 'add'
    },

    // 请求区ლ(´ڡ`ლ)
    // 获取
    async getrefuelinglistfun() {
      const { data } = await GetRefuelingList({
        auto_id: this.id,
        start_time_at: this.start_time_at,
        end_time_at: this.end_time_at,
        current_org: this.current_org,
        ...this.pageData
      })
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.params = data.data.params
      // console.log(this.params)
    },
    // 添加
    async addrefuelingfun(v) {
      var newdata = JSON.parse(JSON.stringify(v))
      newdata.total_price = newdata.total_price !== undefined ? newdata.total_price * 100 : 0
      newdata.unit_price = newdata.unit_price !== undefined ? newdata.unit_price * 100 : 0
      const { data } = await AddRefueling(newdata)
      this.getanpostfun(data, '添加', this.getrefuelinglistfun())
    },
    // 删除
    async delrefuelingfun(id) {
      const { data } = await DelRefueling({ id })
      this.getanpostfun(data, '删除', this.getrefuelinglistfun())
    },
    async editrefuelingfun(ev) {
      // var newdata = JSON.parse(JSON.stringify(ev))
      // newdata.money = newdata.money !== undefined ? newdata.money * 100 : 0
      // const { data } = await EditRefueling(newdata)
      // this.getanpostfun(data, '修改', this.getrefuelinglistfun())
    },
    // 获取弹窗所需数据
    async getallfun(key, cd) {
      // 获取车辆
      const { data } = await GetautoList({ key: key, page: 1, size: 100 })
      // console.log(data)
      this.carList = data.data.list
      this.userlist = data.data.list
      cd(data.data.list)
    },

    // 点击车牌 拉取详情
    async sosuofun() {
      const { data } = await GetRefuelingList({ auto_id: this.auto_id, ...this.pageDatas, start_time_at: this.start_time_ats, end_time_at: this.end_time_ats })
      // console.log(data)
      this.gridData = data.data.list
      this.pageDatas.count = data.data.count
      this.total_prices = data.data.total_price
      this.oil_consumptions = data.data.oil_consumption
    },

    // 导出Excle
    async Daochus() {
      const { data } = await Daochu()
      console.log(data)
    },

    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      // console.log(data)
      this.trashtypeData = data.data
      // console.log(this.trashtypeData)
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>
.nei {
  display: flex;
  justify-content: space-around;
  margin-top: -15px;
}
.texts {
  display: flex;
  width: 240px;
  justify-content: space-around;
  font-size: 16px;
}
#test:hover {
  cursor: pointer;
  color: red;
}
#test {
  text-decoration: underline;
  text-decoration-color: rgba(3, 37, 255, 0.3);
  text-underline-offset: 3px;
}
.kuan {
  width: 200px;
}
.as {
  text-decoration: none;
  /* border: 1px solid #000; */
  width: 98px;
  height: 40px;
  display: inline-block;
  line-height: 43px;
  margin-left: 15px;
  color: white;
  background-color: #409eff;
  border-radius: 5px;
  font-size: 14px;
}
.box {
  display: flex;
  align-items: center;
  text-align: center;
}
.top {
  margin-right: 15px;
}
.bot {
  display: flex;
  align-items: center;
}
.btn {
  width: 100px;
  height: 43px;
}
.riqi {
  margin: -23px 0px 22px -7px;
}
</style>
